
import {useEffect} from 'react';

import {isFirstLoad, allmenu, isSingin} from '../redux/slices/initLoad/types';
import {store} from '../redux/store';
import request from '../helpers/requests';

export const useInit = () => {
  const getInitData = async () => {
    const urlSegments = window.location.pathname.split('/');

    let hash = ''
    if (urlSegments.length > 2) {
       hash = urlSegments[urlSegments.length - 2];
    } else {
      hash = urlSegments[urlSegments.length - 1];
    }
    
    if (hash) {
      localStorage.setItem('hash', hash)
      const {data} = await request.get(`/all/menu/web/${hash}`);
      store.dispatch(allmenu(data));
    }

    store.dispatch(isFirstLoad(true));

    store.dispatch(isSingin(true));
    store.dispatch(isFirstLoad(false));
      
  };

  useEffect(() => {
    setTimeout(()=>{
      getInitData();
    }, 3000)
  }, []);
};



import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import {constants} from '../../config/constants';
import './styles.css';

function Index() {

  const {
    allmenu
  } = useSelector((state) => state.initLoad);

  const [logo, setLogo] = useState('');

  useEffect(()=>{
    const urlSegments = window.location.pathname.split('/');
    const hash = urlSegments[urlSegments.length - 1];
    
    if(hash === '7110eda4d09e062aa5e4a390b0a572ac0d2c0220'){
      setLogo('https://tumenu.online/images/restaurants/logos/6595c759eb5e8.jpg')
    }
    if(hash === '7110eda4d09e062aa5e4a390b0a572ac0d2c4124'){
      setLogo('https://tumenu.online/images/restaurants/logos/66281cc3878fd.jpg')
    }

    if(hash === '7110eda4d09e062aa5e4a390b0a572ac0d2c0221'){
      setLogo('https://tumenu.online/images/restaurants/logos/661879ce67a74.jpg')
    }


    if(hash === '7110eda4d09e062aa5e4a390b0a572ac0d2c4124989'){
      setLogo('https://tumenu.online/images/restaurants/logos/6634eff39e009.jpg')
    }

    
  }, []);
  return (
    <div
      style={{
        backgroundImage: `url(${require('./assets/splash_bk.jpg')})`,
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
      }}
    >
      <img
        className={'logo'}
        src={logo}
        alt="Logo"
      />

      <div style={{
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        backgroundColor: 'black',
        width: '100%'
      }}>
        <p style={{color: 'white'}}>Powered by boostyourmenu.com</p>
      </div>
    </div>
  );
}

export default Index;

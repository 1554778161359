import React, {useState, useEffect} from 'react';
import LayoutContent from '../../../../components/layouts/content2';
import Margin from '../../../../components/shared/margin';
import {styles} from './styles'; 
import ChangeLang from '../../../../components/changeLang';
import { useSelector } from 'react-redux';
import ItemCategorie from '../../../../components/layouts/content2/itemCategorie';
import { constants } from '../../../../config/constants';
import { Link } from 'react-router-dom';

import './style.css';
const Index = () => {
  const [categories, setCategories] = useState([]);

   const [hash, setHash] = useState('');

    useEffect(() => {
      const hash = localStorage.getItem('hash');
      setHash(hash);
    }, []);

  
  const {
    allmenu
  } = useSelector((state) => state.initLoad);


      const [dataDrink, setDataDrink] = useState(false);


      useEffect(() => {
        allmenu.data.map((item) => {
            if (item.id === 60) {
                setDataDrink(item);
            }
        });
      }, [allmenu]);
  

  useEffect(()=>{
    setCategories(allmenu.data);
  }, [allmenu]);

  return (
    <LayoutContent showBack={false}>
      <div className="scroll-view" style={{ zIndex: -1, paddingInline: 220 }} nestedScrollEnabled={true}>
        <div className='content-home'>
          <Margin top={20}/>

          <center>
            <div className='content-categotie-list' style={{
              display: 'flex', flexWrap: 'wrap',
              justifyContent: 'space-between', width: '100%',
              alignSelf: 'center',
              
            }}>
            {categories.map((item, key)=>{
                return <div className='item-categorie-content' key={key} style={{
                
                   width: '49%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                borderRadius: 5,
                padding: 1,
                  marginBottom: 15
                  
                }}>
                    <ItemCategorie item={item} />
                </div>
            })}
          </div>
          </center>
          <br />
        

          <div className='banners-home' style={{ display: 'flex', justifyContent: 'space-between', }}>
             <Link
               
                                    to={`/MenuList/${hash}/${dataDrink.id}`}
              state={{ dataDrink, allDish: allmenu }} style={{  width: '49%'}}>
               <img src={require('./assets/banner1.png')} style={{
              width: '100%',
              objectFit: 'cover',
              borderRadius: 20
             
            }} />
                                    </Link>
           

            <img src={require('./assets/banner2.png')} style={{
              width: '49%',
              objectFit: 'cover',
             
            }} />
          </div>



          <div style={{}}>
            <img src={require('./assets/banner3.png')} style={{
              width: '100%',
              
            }} />
          </div>


          <Margin top={20}/>
        </div>
        <Margin top={100} />
      </div>
    </LayoutContent>
  );
};

export default Index;

import React, { useState, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import translate from '../../../../helpers/translate';
import Margin from '../../../../components/shared/margin';
import Head from '../../../../components/shared/head';
import {commonStyles as stylesCommon} from '../../../../styles/common';
import {useSelector} from 'react-redux';
import Item from './intenList';
import LayoutContent from '../../../../components/layouts/content2';
import { styles } from './styles';
import {useNavigate, useLocation} from 'react-router-dom';


function Index() {
    const data = useLocation().state;
    const urlSegments = window.location.pathname.split('/');
    const categorie_id = urlSegments[urlSegments.length - 1];

    const navigate = useNavigate();
    const {allmenu} = useSelector((state) => state.initLoad);
    
    const commonStyles  = stylesCommon(); 
    
    const {t, i18n} = useTranslation();
    const langEng = i18n.language === 'en' ?? false;
    const [menu, setMenu] = useState([]);
    const [titleCategorie, setIitleCategorie] = useState('');


    useEffect(()=>{
        if (allmenu) {
            const categorieItem = allmenu.data.find(
                (item) => 
                    item.id == categorie_id
                );
        
            if (categorieItem) {
                console.log({categorieItem})
                setMenu(categorieItem.menus);
                setIitleCategorie(langEng ? 
                                categorieItem.name 
                            :    
                                translate(i18n.language, categorieItem.language, categorieItem.name))
            }
        }
    }, [allmenu, categorie_id]);
                            


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);



    let contentStyles;

    if (windowWidth >= 1024) {
      contentStyles = styles.contentRecommendedDesktop;
    } else if (windowWidth >= 768) {
      contentStyles = styles.contentRecommendedTablet;
    } else {
      contentStyles = styles.contentRecommendedMobile;
    }

    
    return (
        <LayoutContent>
            <div style={styles.content}>
                <Head title={titleCategorie} />
                <Margin top={15} />
                <div className='item-list-dish' style={{...contentStyles, ...{paddingInline: 20, position: 'relative'}}}>

                    {menu &&
                        menu.map((item) => (
                            <Item
                                key={item.id.toString()}
                                menu={item}
                                allDish={menu}
                                setShowQr={() => {}}
                                setUriShare={() => {}}
                                titleCategorie={''}
                            />
                        ))
                    }

                 

                </div>
            </div>
            <Margin top={15} />
        </LayoutContent>
    );
}

export default Index;